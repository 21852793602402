export default {
  HOME: 'Klienci biznesowi',
  SIGN_OUT: 'Wyloguj',
  SING_UP: 'Zaloguj',
  PASSWORT_ZUR_CKSETZEN: 'Zresetuj hasło',
  ZUR_CK_ZUM_LOGIN: 'Powrót do logowania',
  RESSET_YOUR_PASSWORD: 'Zresetuj Hasło',
  CONFIRM_RESET_PASSWORD: 'Zapisz nowe hasło',
  CONFIRM_ACCOUT_FIRST: 'Najpierw potwierdź email',
  USER_NOT_FOUND: 'Nie znaleziono użytkownika',
  REGISTER_BUTTON_LINK: 'Rejestracja',
  INVALID_PASSWORD: 'Hasło nieprawidłowe',
  INVALID_LOGIN_OR_PASSWORD: 'Nieprawidałowy login lub hasło',
  EMAIL_SENT: 'Wysłaliśmy Ci aktywacyjny e-mail ',
  ENTER_CODE_FROM_EMAIL:
    'Wprowadź kod z wiadomości e-mail, aby potwierdzić swój adres e-mail.',
  ENTER_THE_CODE: 'Wpisz kod',
  PASSWORD_NOT_MEET_REQUIREMENTS:
    'Hasło nie spełnia wymagań. Można używać tylko cyfr, liter i tych znaków:',
  EMAIL_INVALID: 'e-mail nieprawidłowy',
  USER_ALREADY_EXISITS: 'Użytkownik już istnieje.',
  LOGIN_BUTTON_LINK: 'Zaloguj',
  ACTIVATION_CODE_INVALID: 'Kod jest nieprawidłowy.',
  FEEL_FREE_TO_CONTACT_US: 'Zapraszamy do kontaktu,  pomożemy:',
  CODE_EXPIRED: 'Twój kod wygasł.',
  EMAIL_NOT_FOUND: 'Ten adres e-mail nie istnieje.',
  ACTIVATION_SUCCESFULL: 'Aktywacja zakończona sukcesem',
  ACCOUNT_ACTIVATED:
    'Twój adres e-mail został pomyślnie aktywowany, możesz się teraz zalogować.',
  ENTER_CODE_TO_CHANGE_PASSWORD:
    'Wprowadź kod z e-maila, aby ustawić nowe hasło.',
  CONTACT_US_IF_YOU_HAVENT_RECIEVE_EMAIL:
    'Jeśli nie otrzymałeś e-maila, skontaktuj się z nami:',
  CONFIRM_ACCOUNT_FIRST: 'Najpierw potwierdź swoj email.',
  PASSWORD_CHANGED: 'Hasło poprawnie zmienione.',
  PASSWORD_CHANGED_SUCCESSFULLY:
    'Twoje hasło zostało pomyślnie zmienione, możesz się teraz zalogować.',
  CODE_FROM_EMAIL: 'Kod z e-mail',
  FREE_REGISTER_BUTTON: 'Rejerstracja',
  CONSENT: 'Zapoznałem się i akceptuję warunki',
  TERMS_OF_USE: 'regulaminu',
  ACCEPT: 'oraz',
  PRIVACY_POLICY: 'Polityki prywatności',
  NEXT: 'Dalej',
  SOMETHING_WENT_WRONG: 'Coś poszło nie tak',
  INFO_MAIL: 'kontakt@stretchme.pl',
  EMAIL_ADDRESS: 'Adres E-Mail',
  PASSWORD: 'Hasło',
  VERGESSEN: 'Nie pamiętasz?',
  EINLOGGEN: 'Zaloguj się',
};
